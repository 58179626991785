import styled from '@emotion/styled';

export default styled.div`
  p {
    margin-top: 10px;
  }
  a {
    color: #6534ff;
  }
`;
